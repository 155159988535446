<template>
  <v-container>
    <v-row>
      <v-col class="mb-4">
        <h1 class="text-h6 mb-3 primary--text">¿POR QUÉ MUJERES?</h1>

        <p class="text-h6">
          Porque <span class="secondary--text">repagan mejor que los hombres</span> y, además, reinvierten sus beneficios en la familia en
          mayor porcentaje.
        </p>
      </v-col>

      <!-- <v-col cols="12">
        <v-img :src="require('@/assets/images/why_women.svg')" class="my-3" contain height="200" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WhyWomen",
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bgmobile-whyWomen.png") + ")"
      });
    } else {
      this.$emit("setImageBg", {
        backgroundImage: "url(" + require("@/assets/images/backgrounds/bg-whyWomen.png") + ")"
      });
    }
  }
};
</script>
